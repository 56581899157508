.signup-card,
.login-card,
.error-card,
.contactus-card {
  width: 40rem;
  padding: 25px 55px;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1);
}
.error-until{
  color: rgba(0, 0, 0, 0.50);
  font-weight: 500;
}
@media (max-width: 640px) {
  .signup-card,
  .login-card,
  .error-card {
    padding: 0;
    width: 30rem !important;
  }
  .contactus-card {
    padding: 18px;
  }
}
@media (max-width: 550px) {
  .signup-card,
  .login-card,
  .error-card {
    width: 25rem !important;
  }
}
@media (max-width: 430px) {
  .signup-card,
  .login-card,
  .error-card {
    width: 23rem !important;
  }
}
@media (max-width: 390px) {
  .signup-card,
  .login-card,
  .error-card {
    width: 20rem !important;
  }
}
@media (max-width: 350px) {
  .signup-card,
  .login-card,
  .error-card {
    width: 18rem !important;
  }
  .error-img {
    width: 70%;
  }
}
@media (max-width: 320px) {
  .signup-card,
  .login-card,
  .error-card {
    width: 16rem !important;
  }
}
@media (max-width: 280px) {
  .signup-card,
  .login-card,
  .error-card {
    width: 15rem !important;
  }
}
.resend-email-top {
  margin: auto;
  justify-content: center;
  display: flex;
}
.resend-email-second {
  margin: 50px 0 0 0;
  text-align: center;
}
.account-created-first {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
@media (max-width: 430px) {
  .account-created-first {
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  .account-created-first {
    font-size: 15px;
  }
}
@media (max-width: 280px) {
  .account-created-first {
    font-size: 13px;
  }
}
.error-desc-text-one,
.error-desc-text-two {
  color: rgba(0, 0, 0, 0.50);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
@media (max-width: 768px) {
  .error-desc-text-one {
    display: none;
  }
  .error-desc-text-two {
    font-size: 16px;
  }
  .error-until{
    font-size: 16px;
  }
}
@media (max-width: 560px) {
  .error-btn {
    width: 60% !important;
  }
  .error-btn span,
  .error-desc-text-two, .error-until {
    font-size: 14px !important;
  }
}
@media (max-width: 550px) {
  .error-btn {
    width: 80% !important;
  }
  .error-btn span {
    font-size: 12px !important;
  }
}
@media (max-width: 390px) {
  .error-btn {
    width: 100% !important;
  }
  .error-btn span {
    font-size: 10px !important;
  }
}
@media (max-width: 350px) {
  .error-btn {
    width: 100% !important;
  }
  .error-btn span {
    font-size: 10px !important;
  }
  .error-until{
    font-size: 10px;
  }
  .resend-email-second .email-first {
    font-size: 20px;
  }
  .resend-email-text {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .error-btn {
    width: 100% !important;
  }
  .error-btn span {
    font-size: 10px !important;
  }
  .didnt-recieve-email {
    font-size: 16px;
  }
}
.error-btn {
  margin: auto;
  color: white;
  justify-content: center;
  display: flex;
  width: 80%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #66B855;
}
.error-btn span {
  color: #FFF;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.signup-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: rgba(255, 255, 255, 0);
  }
  .signup {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }