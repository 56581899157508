.org-table {
  height: 100dvh;
  margin: 2rem;
}

.btn-del {
  color: red;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.2rem;
  transition: all .2s linear;
  cursor: pointer;
}

.MuiTableRow-root:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.MuiTableRow-root:nth-of-type(even) {
  background-color: #ffffff;
}

.btn-del:hover {
  color: rgb(253, 92, 92);
}

.btn-edit-organ {
  color: #36454F;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all .2s linear;
  background-color: transparent;
  border: none;
}

.btn-edit-organ:hover {
  color: #4c5f6b;
}

.modal {
  background: rgba(0, 0, 0, .7) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  min-height: 100% !important;
  width: 100% !important;
  align-items: center !important;
  display: flex !important;
  max-width: 800px;
}

.add-btn-admin {
  margin: 2rem;
}

.error-message {
  color: red;
  margin: 3px;
}

.required {
  color: red;
}

.active-status {
  background: #50B482;
  width: 89.25px;
  height: 41.51px;
  top: 329.35px;
  left: 1292.51px;
  gap: 0px;
  border-radius: 5.27px;
}

.inactive-status {
  background-color: rgb(255, 193, 7);
  padding: 0.4rem .6rem;
  border-radius: 10px;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.status-check {
  transform: scale(1.3);
  position: relative;
  left: -6px;
  top: 1px;
}

.search-organ-icon {
  position: absolute;
  right: 9px;
  top: 11px;
  font-size: 18px;
}

.search-field-organ {
  position: relative;
  width: 300px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -1px;
  bottom: 0px;
  background-color: white;
  transition: .4s;
  border: 1px solid darkslategray;
}



.table-container
{
  overflow-x: auto;
  border-radius: 12px; /* Adjust this value as needed */
  border: 0.61px solid #B7B7B7;
}

/* For WebKit browsers (e.g., Chrome, Safari) */
.table-container::-webkit-scrollbar {
  height: 12px; /* Adjust the height of the scroll bar */
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scroll bar track */
  border-radius: 12px; /* Round the corners of the scroll bar track */
}

.table-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll bar thumb */
  border-radius: 12px; /* Round the corners of the scroll bar thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scroll bar thumb on hover */
}

/* For Firefox */
.table-container {
  scrollbar-width: thin; /* Make the scroll bar thin */
  scrollbar-color: #888 #f1f1f1; /* Color of the scroll bar thumb and track */
}

/* For Internet Explorer and Edge */
.table-container {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-container::-ms-scrollbar {
  width: 12px; /* Adjust the width of the scroll bar */
}

.table-container::-ms-scrollbar-track {
  background: #f1f1f1; /* Background of the scroll bar track */
  border-radius: 12px; /* Round the corners of the scroll bar track */
}

.table-container::-ms-scrollbar-thumb {
  background: #888; /* Color of the scroll bar thumb */
  border-radius: 12px; /* Round the corners of the scroll bar thumb */
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.flag-img {
  height: 30px;
  width: 30px;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 31px;
  right: 16px;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: #f1f1f1;
  color: black !important;
}

.react-tel-input
{
  margin-top: 10px !important;
}
.protocol-field {
  width: 150px !important;
  color: gray;
}

#select-country {
  color: gray;
}

.website-protocol-area {
  gap: 1rem;
}

@media (min-width: 576px) and (max-width: 600px) {
  .modal-dialog {
    max-width: 520px;
  }
}

@media (max-width: 575px) {
  .modal-dialog {
    max-width: 530px;
  }

  .protocol-field {
    width: 100% !important;
  }
}

@media (max-width: 540px) {
  .modal-dialog {
    margin: 0;
  }

  .search-field-organ {
    width: auto;
  }
}

@media (max-width: 767px) {
  .scroll-modal-body {
    height: 500px;
    overflow-y: scroll;
  }
}

.org-btn {
  background: linear-gradient(90deg, #56367F 0%, #3A5697 100%);
  width: 159px;
  height: 55px;
  top: 845.21px;
  left: 978px;
  gap: 0px;
  border-radius: 7px;  
  color: white;
}

.org-btn:hover {
  color: #f1f1f1;
}

.org-btn-close {
  background-color: #808080;
  width: 159px;
  height: 55px;
  top: 845.21px;
  left: 978px;
  gap: 0px;
  border-radius: 7px;
  color: white;
}

.org-btn-close:hover {
  color: #f1f1f1;
  background-color: #808080;
}

.heading-style {
  font-size: 16px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: left;
  color: #101828;

}
.form-control {
  width: 100%;
  height: 55px;
  padding: 10px 15px;
  border-radius: 5px;
  background: #F0F3F6;
  margin-top: 10px;
  border: none;
  display: flex;
  align-items: center;
}

.phone-input .form-control {
  width: 100% !important;
  height: 55px !important;
  border-radius: 5px !important;
  background: #F0F3F6 !important;
  border: none !important;
  margin-top: 10px !important;
  display: flex !important;
  align-items: center !important;
 
}

.phone-input .form-control .flag-dropdown {
  order: 1;
  padding-left: 0 !important; /* Remove padding to eliminate gap */
  margin-left: auto; /* Align flag to the end */
 }

.phone-input .form-control input {
  order: 0;
  flex-grow: 1;
  width: calc(100% - 40px) !important;
  height: 55px !important;
  padding: 10px !important;
  border-radius: 5px !important;
  background: #F0F3F6 !important;
  border: none !important;
  margin-top: 10px !important;
}

.custom-placeholder
{
  font-size: 14px;
}

