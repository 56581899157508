.App {
  text-align: center;
}

/* jkjkhkjh */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.login-bg {
  background-image: url("./assets/adminbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.bg-blur-login {
  min-height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.offcanvas-end {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  width: 80%;
}

.offcanvas-end.show {
  transform: translateX(0);
}

.offcanvas-body {
  padding: 15px;
  background-color: #fff;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.image-container {
  position: absolute;
  top: 28%;
  right: -50px;
  margin-right: 20px;
}

.form-container {
  padding: 20px;
}

.button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: floralwhite;
  padding: 0.5rem 10rem;
  border-radius: 5rem;
  transition: 0.3s;
  background-color: #5cb3cf;
  font-weight: bold;
  font-size: 17px;
  border: none;
  width: 100%;
  flex-shrink: 0;
  white-space: normal;
}

.button--flex {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
}

@media screen and (max-width: 768px) {
  .button {
    padding: 0.5rem 6rem;
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .button {
    padding: 0.5rem 4rem;
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .button {
    padding: 0.5rem 3rem;
    font-size: 13px;
  }
}

.arrow-line {
  text-align: center;
  display: flex;
  align-items: center;
}

.arrow-line p {
  display: inline-block;
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 10px;
}

.arrow-line::before,
.arrow-line::after {
  content: "";
  display: inline-block;
  flex-grow: 1;
  height: 1px;
  background-color: #000;
}

.arrow-line::before {
  margin-right: 10px;
}

.arrow-line::after {
  margin-left: 10px;
}

.rectangle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 45px;
  border: 2px solid black;
  margin: 10px;
  cursor: pointer;
  color: black;
  padding: 0.5rem 0rem;
  border-radius: 5rem;
  transition: 0.3s;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.rectangle i {
  font-size: 15px;
  color: black;
  margin-right: 5px;
}

.mb-3 {
  position: relative;
}

.form-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-label {
  flex: 1;
  margin-right: 10px;
}

.form-text {
  flex-shrink: 0;
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .form-text-container {
    flex-wrap: wrap;
  }

  .form-label {
    margin-bottom: 10px;
  }
}

.welcomeBack {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 127.5%;
}

.toStay {
  color: #fff;
  text-align: center;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 127.5%;
}

.signInBtn {
  border-radius: 5px;
  background: #30b596;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(30px);
  width: 100%;
  max-width: 260px;
  height: 42px;
  flex-shrink: 0;
  color: floralwhite;
  transition: 0.3s;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #30b596;
  margin: 7px;
  cursor: pointer;
}

.circle i {
  color: white;
}

.textInput {
  display: flex;
  width: 318px;
  height: 42px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #3eb599;
}

.homeBtn {
  display: flex;
  width: 130px;
  height: 32px;
  font-size: 14px;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #04d39c;
  color: floralwhite;
  transition: 0.3s;
}

.custom-dropdown .dropdown-menu {
  left: -195px;
}

.dropdown-width {
  width: 290px;
  margin-left: -182px;
}

.img-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.img-container img {
  max-width: 300px;
}

.week-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

.circle-container {
  text-align: center;
}

.circle {
  cursor: pointer;
  display: inline-block;
  color: floralwhite;
  padding: 0.5rem 0.5rem;
  border-radius: 50%;
  transition: 0.3s;
  background-color: #f0a500;
  font-size: 1.1vw;
  border: none;
  display: inline-flex;
  align-items: center;
}

.highlight {
  background-color: #3399ff;
  color: #fff;
}

@media (max-width: 518px) {
  .week-container {
    flex-wrap: wrap;
  }
}

.navbar-nav .nav-link {
  position: relative;
  display: inline-block;
  padding: 20px 10px;
  border-radius: 40px;
  white-space: nowrap;
  text-align: start;
}

.nav-link-home {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-link-home:hover {
  color: var(--bs-nav-link-color);
}

.navbar-nav .nav-item:hover .nav-link::after {
  transform: translateX(-50%) scale(1);
}

@media (max-width: 600px) {
  .card1 {
    width: 80%;
    height: 70%;
  }
}

.circle-image {
  position: absolute;
  top: 5%;
  right: 15%;
  width: 40%;
  max-width: 350px;
  height: auto;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .circle-image {
    top: 10%;
    right: 10%;
    width: 50%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .circle-image {
    top: 15%;
    right: 5%;
    width: 60%;
    max-width: 250px;
  }
}

.card {
  cursor: pointer;
}

ul.checklist {
  list-style: none;
  padding: 0;
}

ul.checklist li {
  position: relative;
  padding-left: 40px;
  line-height: 1.5;
}

ul.checklist li:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
  text-align: center;
}

ul.checklist li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: white;
  position: absolute;
  left: 4px;
  top: 0;
  font-size: 14px;
}



ul.checklist-2 {
  list-style: none;
  padding: 0;
}

ul.checklist-2 li {
  position: relative;
  padding-left: 40px;
  line-height: 1.5;
}

ul.checklist-2 li:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
  text-align: center;
}

ul.checklist-2 li:before {
  content: "\f12a";
  font-family: "FontAwesome";
  color: white;
  position: absolute;
  left: 4px;
  top: 0;
  font-size: 14px;
}

.rounded-lg {
  border-radius: 200px;
}

.highlighted {
  background-color: white;
  border-radius: 200px;
  padding: 12px;
  margin: 0;
  width: 35%;
}

.col-md-8.offset-md-2 p {
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.selected-card {
  background-color: red;
  color: white;
}

.customWidth .MuiBottomNavigationAction-label {
  min-width: 190px;
}

.progress-container {
  position: relative;
  width: 100%;
}

.progress {
  height: 20px;

  background-image: linear-gradient(to right, white 0%, lightgray 170%);
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.ruler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
}

.ruler-mark {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  text-align: center;
  width: calc(100% / 18);
}

.ruler-line {
  position: absolute;
  top: -6px;
  width: 1px;
  height: 10px;
  background-color: #333;
}

.ruler-value {
  position: absolute;
  top: -18px;
  left: 0;
  transform: translateX(-50%);
  font-size: 12px;
  color: #333;
}

.boring-text,
.normal-text,
.fast-text {
  position: absolute;
  top: 25px;
  left: 0;
  font-size: 1vw;
  color: #333;
}

@media (max-width: 450px) {

  .ruler-line,
  .ruler-value {
    display: none;
  }

  .ruler-mark {
    padding: 0 20% 0 0;
  }

  .fast-text {
    white-space: normal;
  }
}


.accordion .accordion-item .accordion-header button {
  color: black;
}

.accordion-button.collapsed {
  background: red;
}

.btn-large-screen {
  margin-top: 12px;
  font-size: 14px;
  padding: 10px 10px;
}

.btn-small-screen {
  margin-top: 10px;
}

.card-larger-screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 33px;
  background-color: #F9F9FA;
  border-radius: 18px;
}

.card-small-screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 33px;
  background-color: #F9F9FA;
  border-radius: 18px;
  flex-direction: column;
}

.question-center {
  padding: 0 130px;
  text-align: center;
  color: rgb(192, 184, 184);
}

@media (max-width: 991px) {
  .question-center {
    padding: 0;
    text-align: left;
  }
}

.app-loader-animation {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.app-loader {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 50px
}

.cv-progress-animation {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
}

.cv-progress {
  width: 40%;
  height: 40%;
}

@media (max-width: 800px) {
  .app-loader {
    padding: 200px
  }
}

@media (max-width: 645px) {
  .app-loader {
    padding: 100px
  }
}

@media (max-width: 500px) {
  .app-loader {
    padding: 20px
  }
}

@media (min-width: 1024px) {
  .app-loader {
    padding: 100px
  }
}

.logout-buttons .button {
  border-radius: 50%;
  border-width: 50%;
  border-color: #04d39c;
}

.jumbotron {
  margin: 0px auto;
  padding: 240px 30px;
  background-color: #5CB3CF;
  color: floralwhite;
}

.project-button {
  background-color: #5cb3cf;
  color: floralwhite;
  font-weight: bold;
}

.contact-bg {
  background-color: #f1f1f5;
  border-radius: 12px;
  width: 20%;
  margin: auto;
  display: block;
  text-align: center;
  align-items: center;
}

.contact-p {
  color: #5CB3CF;
  font-weight: bold;
}

.contentFooter {
  padding: 5% 20% 0 20%;
  text-align: center;
  border: none;
  margin-top: 650px;
}

@media (max-width: 1000px) {
  .contentFooter {
    margin-top: 500px;
  }
}

@media (max-width: 767px) {
  .contentFooter {
    margin-top: 720px;
  }
}

@media (max-width: 550px) {
  .contentFooter {
    margin-top: 800px;
  }
}

@media (max-width: 358px) {
  .contentFooter {
    margin-top: 840px;
  }
}

@media (max-width: 306px) {
  .contentFooter {
    margin-top: 860px;
  }
}

@media (max-width: 289px) {
  .contentFooter {
    margin-top: 890px;
  }
}

@media (max-width: 258px) {
  .contentFooter {
    margin-top: 920px;
  }
}

@media (max-width: 250px) {
  .contentFooter {
    margin-top: 950px;
  }
}

.login {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.login::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(241, 197, 16, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), url("./assets/img/admin-login.png") center top / cover no-repeat;
  z-index: -1;
}


.login-container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0);
}

.admin-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: url("./assets/adminbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.admin-card {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 130px 130px 60px 95px;
}

.admin-login-img img {
  transition: transform 0.3s ease-in-out;
}

.admin-login-img img:hover {
  transform: perspective(300px) rotateX(-5.1deg) rotateY(-2.06deg) scale3d(1.1, 1.1, 1.1);
}

.admin-login-form {
  width: 290px;
  float: right;
}

.admin-login-title {
  font-family: 'Poppins-Bold';
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 54px;
}

.admin-login-fields {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #e6e6e6;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.admin-login-button-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.admin-login-button {
  outline: none !important;
  border: none;
  font-family: 'Poppins-Bold';
  font-weight: bold;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #43ADDB;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.txt1 {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
  line-height: 1.5;
  color: #999999;
}

.txt2 {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
  line-height: 1.5;
  color: #666666;
}

a {
  text-decoration: none;
  margin: 0;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  height: 2em !important;
  padding: 15px 0 5px !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 12px !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin: 0 0 0 -25px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: none !important;
  background-color: #43ADDB !important;
}

.error-fields {
  font-size: 14px;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-9msfkk-MuiFormControl-root-MuiTextField-root {
  border-radius: 30px !important;
}

.bgWhite {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
  border-radius: 30px;
  height: 430px;
}

.verify-title {
  font-weight: 600;
  margin-top: 70px;
  font-size: 24px
}

.customBtn {
  border-radius: 25px;
  padding: 10px 20px;
}

.verify-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  margin-left: 15px;
}

.admin-reset-form {
  padding: 0 20%;
}