body {
  font-family: "Roboto", sans-serif !important;
}

nav {
  background-color: #fff !important;
  transition: all 0.3s linear;
}

#hero {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.open-nav {
  height: 220px;
  overflow: hidden;
}

.profile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.nav-link {
  font-size: 16px !important;
  /* font-weight: bold !important; */
  color: white !important;
  transform: all 0.3s linear !important;
  margin-top: 10px;
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.common-icon {
  width: 24px;
  /* Adjust size as needed */
  height: 24px;
  margin-right: 10px;
  /* Adjust spacing as needed */
  margin-top: -20px;
}

.item-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* Adjust for desired spacing */
  opacity: 0.5;
  /* Optional: Adjust transparency */
}

.nav-link-home {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-link-home:hover {
  color: var(--bs-nav-link-color);
}

.nav-item:hover .nav-link::after {
  transform: translateX(-50%) scale(1);
}

.dropdown-menu {
  background-color: #f5f5f5;
  padding: 0;
  width: 250px;
  height: fit-content;
}

.dropdown-item {
  padding: 10px 20px;
}

.user-name {
  color: gray !important;
  font-weight: bold;
}

.navbar {
  position: relative;
}

.toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: gray;
}

.mobile-nav {
  background: #fff;
  position: fixed;
  width: 200px;
  right: -200px;
  top: 40px;
  bottom: 0;
  padding: 1rem !important;
  transition: all 0.3s linear;
  z-index: 10;
}

.open-mobile-nav {
  right: 0;
}

.close-icon {
  font-size: 1.5rem;
  color: black;
}

.close-btn {
  background: grey;
  width: 159px;
  height: 55px;
  top: 845.21px;
  left: 978px;
  gap: 0px;
  border-radius: 7px;
  opacity: 0px;
  color: white;
}

.speak-test,
.predictive {
  border-radius: 8px;
  background: #f5f5f5;
  border: 4px solid transparent;
  border-bottom-color: #f4b844;
  flex: 1;
  margin: 0 2px;
}

.user-dash-div {
  border-radius: 16px;
  background: #f5f5f5;
  border: 4px solid transparent;
  flex: 1;
  margin: 0 2px;
}

.test-section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.test-section {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
}

.test-title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.content {
  font-size: 16px;
  font-weight: normal;
  color: #000;
  margin: 0;
}

.plan {
  text-decoration: none;
  color: #005ce7;
  font-weight: 600;
}

.cvUpload {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-top: 3rem;
}

.polygon {
  width: 250px;
  height: 250px;
  object-fit: contain;
  margin-top: 1rem;
}

.report_polygon {
  width: 360px;
  height: 360px;
  object-fit: contain;
}

.report-chart {
  max-width: 550px;
}

.level-text {
  font-size: 32px;
  padding: 0 0 0 3%;
  bottom: 140px;
}

.test-start {
  text-decoration: none;
  border-radius: 5px;
  background: #43addb;
  width: 130px;
  height: 32px;
  text-align: center;
  color: white;
  line-height: 2;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s linear;
  display: block;
}

.test-start:hover {
  background: #399cc7;
  color: white;
}

.robot {
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin-top: 1rem;
}

.user-dash-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-top: 1.8rem;
}

.faq-work {
  border-radius: 8px;
  background: #f5f5f5;
  border: 4px solid transparent;
}

.faq-prepare {
  border-radius: 8px;
  background: #f5f5f5;
  border: 4px solid transparent;
}

.faq-heading {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.question {
  font-size: 16px;
  font-weight: bold;
  color: #a5a5a9;
  transition: color 0.5s ease-in-out;
}

.answer {
  font-size: 16px;
  font-weight: normal;
  color: #615e64;
}

.question-section {
  border-bottom: 2px solid #aeaeaf;
}

.collapse-area {
  transition: all 0.5s ease-in-out;
  height: 50px;
  overflow: hidden;
  cursor: pointer;
}

.collapse-show {
  height: 120.59px;
}

.question-active {
  color: #000;
}

.cant-find {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.email {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  opacity: 0.8;
}

.info-bg {
  background: #43addb;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email-link {
  text-decoration: none;
  color: #fff;
}

.email-link:hover {
  text-decoration: underline;
  color: white;
}

.footer-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
}

.bg-footer {
  background-color: #04d39c;
  height: 135px;
}

.social-icon {
  border: 2px solid white;
  border-radius: 50%;
  font-size: 1rem;
  height: 40px;
  width: 40px;
  padding: 9px;
  color: white;
  cursor: pointer;
  transition: all 0.3s linear;
}

.social-icon:hover {
  background-color: #000;
  color: white;
  border-color: black;
}

.copy-right {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}

.navigate-up {
  position: fixed;
  z-index: 3;
  right: 4rem;
  bottom: 3rem;
  background: gray;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  animation: pop-up 2s ease infinite;
  border: none;
}

.navigate-up:hover {
  color: white;
}

.container-bar {
  width: 60%;
  margin: 0 auto;
}

.bar-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.bar-progress::before {
  content: "";
  position: absolute;
  background-color: #f3f3f3;
  left: 33px;
  border-radius: 5px;
  height: 10px;
  z-index: -1;
  transform: translateY(-50%);
  transition: all 1s ease;
  width: 90%;
  top: 50%;
}

.progres {
  position: absolute;
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  width: 50%;
  height: 10px;
  z-index: -1;
  top: 50%;
  left: 33px;
  transform: translateY(-50%);
  transition: all 1s ease;
  border-radius: 5px;
}

.right-info {
  width: 405px;
  background-color: #f5f5f5;
  border-radius: 10px;
  height: 470px;
  overflow-y: auto;
}

.s-admin-info {
  column-gap: 1rem;
}

.s-admin-logo {
  background: #50b482;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.admin-active-title {
  text-shadow: 0 0 BLACK;
  color: #171717;
  font-size: 1.5rem;
}

.admin-active-title-1 {
  text-shadow: 0 0 BLACK;
  color: #171717;
  font-size: 1.5rem;
}

.circle-cv {
  background-color: #f5f5f5;
  height: 65px;
  width: 65px;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  transition: all 1s ease;
  flex-direction: column;
}

.circle {
  background-color: #fff;
  height: 65px;
  width: 65px;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: 3px solid #dddfe1;
  border-radius: 50%;
  transition: all 1s ease;
  flex-direction: column;
}

.circle-cv.active {
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  color: white;
  font-weight: bold;
  border-image: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%);
  border-image-slice: 1;
}

.circle.active {
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  color: white;
  font-weight: bold;
  border-image: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%);
  border-image-slice: 1;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: -23px;
  top: 18px;
}

.caption {
  margin-top: 8px;
  text-align: center;
}

.caption-bar {
  font-weight: bold;
  color: #464a53;
  font-size: 16px;
  position: relative;
  top: 10px;
}

.bg-white {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
  border-radius: 30px;
  height: auto;
  width: 600px;
}

.reset-btn-modify {
  width: 200px;
  outline: none;
  border: none;
}

@keyframes pop-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 1166px) {
  .content br {
    display: none;
  }
}

@media (min-width: 470px) and (max-width: 620px) {
  .collapse-show {
    height: 135px;
  }
}

@media (min-width: 260px) and (max-width: 470px) {
  .collapse-show {
    height: 170px;
  }

  .answer {
    font-size: 15px;
  }
}

@media (max-width: 1040px) {
  .test-section {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 1060px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 2rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 126px;
  }
}

@media (max-width: 990px) {
  .report_polygon {
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-top: 4rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 110px;
  }
}

@media (max-width: 900px) {
  .report_polygon {
    width: 240px;
    height: 240px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 100px;
  }
}

@media (max-width: 870px) {
  .report_polygon {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 6rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 7%;
    bottom: 98px;
  }
}

@media (max-width: 767px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 1rem;
    margin-left: -5rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 11% 0 0;
    bottom: 136px;
  }
}

@media (max-width: 600px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 1rem;
    margin-left: -1rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 136px;
  }
}

@media (max-width: 445px) {
  .report_polygon {
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-top: 3rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 120px;
  }
}

@media (max-width: 400px) {
  .report_polygon {
    width: 230px;
    height: 230px;
    object-fit: contain;
    margin-top: 3rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 120px;
  }
}

@media (max-width: 390px) {
  .report_polygon {
    width: 220px;
    height: 220px;
    object-fit: contain;
    margin-top: 4rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 112px;
  }
}

@media (max-width: 375px) {
  .report_polygon {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 108px;
  }
}

@media (max-width: 350px) {
  .report_polygon {
    width: 180px;
    height: 180px;
    object-fit: contain;
    margin-top: 5rem;
    margin-left: -1rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 0 0 0%;
    bottom: 115px;
  }
}

@media (max-width: 330px) {
  .report_polygon {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 5% 0 0;
    bottom: 115px;
  }
}

@media (max-width: 300px) {
  .report_polygon {
    width: 130px;
    height: 130px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 5% 0 0;
    bottom: 120px;
  }
}

@media (max-width: 280px) {
  .report_polygon {
    width: 110px;
    height: 110px;
    object-fit: contain;
    margin-top: 7rem;
  }

  .level-text {
    font-size: 15px;
    padding: 0 5% 0 0;
    bottom: 107px;
  }
}

@media (max-width: 410px) {
  .cvUpload {
    height: 170px;
    width: 170px;
  }

  .robot {
    height: 200px;
    width: 200px;
  }

  .polygon {
    height: 250px;
    width: 250px;
  }

  .content {
    font-size: 14px;
  }
}

@media (max-width: 417px) {
  .question {
    font-size: 16px;
  }
}

@media (min-width: 560px) and (max-width: 725px) {
  .cant-find {
    font-size: 34px;
  }
}

@media (min-width: 470px) and (max-width: 559px) {
  .cant-find {
    font-size: 28px;
  }

  .email {
    font-size: 16px;
  }
}

@media (min-width: 260px) and (max-width: 470px) {
  .cant-find {
    font-size: 22px;
  }

  .email {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .footer-grid {
    grid-template-columns: 1fr !important;
  }

  .offer {
    padding-left: 1rem;
    padding-top: 1.5rem;
  }

  .foot-contact {
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
}

@media (max-width: 600px) {
  .navigate-up {
    height: 35px;
    width: 35px;
    right: 3rem;
    bottom: 2rem;
  }

  .social-icon {
    height: 45px;
    width: 45px;
  }
}

@media (max-width: 334px) {
  .cvUpload {
    height: 150px;
    width: 150px;
  }

  .polygon {
    height: 200px;
    width: 200px;
  }

  .robot {
    height: 150px;
    width: 150px;
  }

  .collapse-show {
    height: 190px;
  }

  .navigate-up {
    height: 35px;
    width: 35px;
    right: 2rem;
    bottom: 2rem;
  }

  .cant-find {
    font-size: 18px;
  }

  .email {
    font-size: 13px;
  }
}

@media (max-width: 414px) {
  .mobile-nav {
    width: 100%;
    right: -420px;
  }

  .open-mobile-nav {
    right: 0px;
  }

  .copy-write {
    font-size: 14px;
  }
}

@media (min-width: 1041px) and (max-width: 1316px) {
  .collapse-show {
    height: 150px;
  }
}

@media (min-width: 945px) and (max-width: 1100px) {
  .container-bar {
    width: 70%;
  }
}

@media (min-width: 850px) and (max-width: 944px) {
  .container-bar {
    width: 80%;
  }
}

@media (min-width: 450px) and (max-width: 849px) {
  .container-bar {
    width: 80%;
  }

  .bar-progress::before {
    left: 7px;
  }

  .progres {
    left: 7px;
  }

 
}

@media (max-width: 580px) {
  .caption-bar {
    font-size: 14px;
  }

  .bar-progress::before {
    left: 7px;
  }

  .progres {
    left: 7px;
  }

  .circle-container {
    left: -5px;
  }
}

@media (max-width: 490px) {
  .bar-progress::before {
    left: 20px;
  }

  .progres {
    left: 20px;
  }

  .caption-bar {
    font-size: 14px;
  }

  .circle {
    height: 50px;
    width: 50px;
    font-size: 14px;
  }
}

@media (max-width: 449px) {
  .container-bar {
    width: 100%;
  }
  .btn-primary-responsivesness
  {
    margin-top: 20px;
  }
}

@media (max-width: 280px) {
  .container-bar {
    width: 100%;
  }

  .bar-progress::before {
    left: 20px;
  }

  .progres {
    left: 20px;
  }

  .caption-bar {
    font-size: 12px;
  }

  .circle {
    height: 45px;
    width: 45px;
    font-size: 14px;
  }
}

.close-div {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 1.7rem;
  display: none !important;
}

.sidebar {
  width: 300px;
  transition: all 0.3s linear;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);

  /* height: 100vh; */
}

.sidebar-collapse {
  width: 0;
  overflow: hidden;
}

.open-sidebar {
  width: 300px !important;
  overflow: visible !important;
}

.side-title {
  font-size: 20px;
  margin-top: 3.25rem;
  color: #5f00d9;
}

.side-content {
  height: 88%;
}

.nav-items-2 {
  transition: all 0.3s linear;
  cursor: pointer;
  font-weight: 500;
  border-radius: 10px;
  gap: 1rem;
  white-space: nowrap;
}

/* .nav-items-2:hover
{
  background: #FFFFFF45;
  color: white;
} */

.nav-items-2.active {
  background-color: #ffffff45;
  color: white;
}

.top-nav {
  padding: 1rem 1rem 1rem 2rem;
  background: #eeeef4;
}

.left-super-info {
  width: 100%;
}

.btn-user {
  font-size: 2rem !important;
}

.btn-toggle {
  font-size: 2rem !important;
}

.background {
  background-color: #eeeef4;
  padding: 24px 0;
}

.btn-close {
  color: white;
}

.dashboard-top {
  margin: 0rem 10rem;
  padding: 0rem 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.s-dashboard-top {
  margin: 2rem 2rem;
  padding: 0rem 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
}

.total {
  background: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-bottom: 40px;
}

.caption {
  font-size: 14px;
  font-weight: 500;
  color: #535d66;
  margin-bottom: 8px;
  text-shadow: 0 0 #535d66;
  text-align: left;
}

.content {
  font-size: 24px;
  font-weight: 500;
  color: #171717;
}

.valid-user {
  font-size: 2rem;
}

.cv-title-font {
  font-size: 16px;
}

.tag {
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  background: #f3f3f7;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
  color: #797e82;
  position: relative;
  top: -4px;
  letter-spacing: 1px;
}

.balance {
  gap: 9rem;
}

.btn-deposit,
.btn-withdraw {
  height: 40px;
  width: 130px;
  border-radius: 10px;
  background-color: #43addb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 500;
  text-shadow: 0 0 white;
  transition: all 0.3s linear;
  outline: 0;
  border: 0;
}

.btn-deposit:focus,
.btn-withdraw:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.transactions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 10rem;
  gap: 2rem;
}

.s-transactions {
  margin: 2rem 2rem;
  gap: 2rem;
}

.price-side {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1.2rem;
  position: relative;
  overflow-x: auto;
}

.trans-side {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1.2rem;
}

.profit {
  color: #f5f5f5;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: -2px;
}

.tab {
  border: none;
  height: 25px;
  width: 30px;
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #797e82;
  font-weight: 500;
  font-size: 13px;
}

.tab:focus {
  background-color: white;
}

.tab-active {
  background-color: white;
}

.tab:hover {
  background-color: white;
}

.date {
  font-size: 12px;
  color: #797e82;
  margin-bottom: 0;
  margin-top: 1rem;
}

.img-area {
  position: relative;
}

.graph {
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
}

.line {
  width: 100%;
  object-fit: cover;
}

.dollar {
  height: 40px;
  width: 40px;
  background: #f3f3f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.1rem;
  text-shadow: 0 0 #171717;
  font-weight: 500;
}

.date-time {
  font-size: 14px;
  font-weight: 400;
  color: #797e82;
}

.trans-price {
  font-size: 18px;
  color: #171717;
  font-weight: 600;
}

.btn-block {
  width: 100%;
  background-color: #eeeef4 !important;
  border-radius: 10px !important;
  color: #171717 !important;
  font-weight: 500 !important;
  height: 50px !important;
}

.increment {
  font-size: 14px;
  color: #797e82;
  font-weight: 400;
}

.dashboard-footer {
  margin: 0 10rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 10px;
}

.loan-tag {
  background: #5f00d9;
  color: white;
  border-radius: 10px;
  display: inline;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
}

.dashfoot-content {
  font-size: 18px;
  font-weight: 500;
  color: #171717;
  padding-right: 5rem;
  margin-top: 1rem;
}

.contact-content {
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding-right: 5rem;
  margin-top: 1rem;
}

.contact-tag {
  background: white;
  color: black;
  border-radius: 10px;
  display: inline;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
}

.export-area {
  padding: 0 10rem;
}

th {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}

.table-width {
  width: 85%;
  margin: 1rem auto;
  background: white;
  border-radius: 10px;
}

.thead-border {
  border-bottom: 1px solid lightgray;
  padding: 1rem !important;
}

.font-style {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #101828;
}

.thead-tabs {
  gap: 1rem 1rem;
}

.table-tab {
  width: 105px;
  justify-content: center;
  border: 2px solid transparent;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.table-tab:hover {
  border-bottom: 2px solid blue;
  font-weight: bold;
}

.search-field {
  position: relative;
}

.search-input {
  width: 230px;
  padding-left: 1.5rem;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  font-size: 14px;
}

.search-input:focus-visible {
  border: none;
  outline: none;
}

.search-icon {
  font-size: 1.2rem;
  position: absolute;
  background-color: transparent;
  border: none;
  left: -5px;
  top: 2px;
  cursor: pointer;
}

.search-icon:hover {
  font-weight: bold;
}

.category {
  font-size: 12px;
  font-weight: 500;
  color: #797e82;
}

.table-tag {
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  background: #f3f3f7;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
  color: #797e82;
}

.table-border {
  border-bottom: 1px solid lightgray !important;
}

.table-content {
  padding: 0 2rem;
}

thead {
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  color: white;
  font-weight: 400;
}

.table-caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #797e82;
  text-shadow: #797e82;
  margin-bottom: 0;
}

.thead-content {
  color: #797e82;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
}

.search-area {
  position: relative;
  text-align: right;
}

.search-field {
  border: none;
  height: 35px;
  font-size: 1.1rem;
}

.search-field:focus-visible {
  border: none;
  outline: none;
}

.search-icon {
  font-size: 1.5rem;
  position: absolute;
  right: 0;
}

.search-btn {
  background: transparent;
  border: none;
}

.animation-loader-width {
  padding: 0 !important;
  width: 65% !important;
}

.admin-name {
  font-weight: bold;
  position: relative;
  top: 2px;
  right: 2px;
}

.btn-user:focus {
  box-shadow: none !important;
}

.btn-toggle:focus {
  box-shadow: none !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.graph-caption {
  font-weight: 500;
  text-shadow: 0 0 black;
}

.nav-btn {
  text-decoration: none;
  color: black;
  transition: color 0.3s linear;
  font-size: 1.2rem;
}

.super-drop-item {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-size: 1rem;
}

.super-drop-item:hover {
  background-color: #eeeef4 !important;
  color: #797e82 !important;
}

.dropdown-super {
  color: rgb(121, 126, 130) !important;
  font-weight: bold;
}

.nav-btn:hover {
  color: #43addb;
}

/* Menu icon for smaller screens (tablets and iPads) */
@media (max-width: 1024px) {
  .btn-user,
  .btn-toggle {
    display: block !important;
    font-size: 1.5rem !important;
  }

  .sidebar {
    width: 0;
    overflow: hidden;
  }

  .open-sidebar {
    width: 300px !important;
    overflow: visible !important;
  }

  .top-nav {
    padding: 1rem 2rem;
  }

  .dashboard-top {
    margin: 0rem 2rem;
  }

  .s-dashboard-top {
    grid-template-columns: 1fr;
  }

  .balance {
    gap: 3rem;
  }

  .transactions {
    margin: 2rem 2rem;
  }

  .dashboard-footer {
    margin: 0 2rem;
    padding-bottom: 3rem;
  }
}

/* For laptop screens */
@media (min-width: 1025px) and (max-width: 1366px) {
  .body {
    overflow-y: hidden;
  }

  .s-dashboard-top {
    grid-template-columns: repeat(5, 1fr);
    gap: 0.8rem;
  }

  .total {
    max-height: 110px;
    width: 174px;
  }

  .cv-title-font {
    font-size: 11.8px;
  }

  .item-icon {
    width: 60px;
    height: 60px;
    opacity: 1;
  }

  .navigation-card {
    width: 200px !important;
    margin-top: 15px !important;
    margin-left: 40px;
  }

  .cv-box {
    width: 215px !important;
    margin-left: 30px !important;
  }

  .analytics-graph-width {
    width: 103% !important;
  }

  .bg-organization {
    width: 104% !important;
  }

  .admin-active-title-1 {
    margin-left: 50px;
  }

  .table-responsive {
    height: 155px !important;
  }
}

@media (max-width: 768px) {
  .table-responsive {
      height: 175px !important; /* Adjust this value as needed */
  }
}

/* Hide menu icon on larger screens (laptops and desktops) */
@media (min-width: 1025px) {
  .btn-toggle {
    display: none !important;
  }

  .sidebar {
    width: 300px;
  }

  .top-nav {
    padding: 1rem 1rem 1rem 2rem;
  }

  .dashboard-top {
    margin: 0rem 10rem;
  }

  .balance {
    gap: 9rem;
  }

  .transactions {
    margin: 2rem 10rem;
  }

  .dashboard-footer {
    margin: 0 10rem;
  }
}

/* @media (min-width:1400px) {
  .btn-toggle {
    display: none !important;
  }
}

@media (max-width:1399px) {

  .btn-user,
  .btn-toggle {
   
    font-size: 1.5rem !important;
  }

  .top-nav {
    padding: 1rem 2rem;
  }

  .sidebar {
    width: 0;
    overflow: hidden;
  }

  .dashboard-top {
    margin: 0rem 2rem;
  }

  .balance {
    gap: 3rem;
  }

  .transactions {
    margin: 2rem 2rem;
  }

  .dashboard-footer {
    margin: 0 2rem;
    padding-bottom: 3rem;
  }
} */

@media (min-width: 1650px) and (max-width: 1880px) {
  .balance {
    gap: 5rem;
  }

  .dashboard-top {
    margin: 0 6rem;
  }

  .transactions {
    margin: 2rem 6rem;
  }

  .dashboard-footer {
    margin: 0 6rem;
  }
}

@media (min-width: 1400px) and (max-width: 1649px) {
  .balance {
    gap: 2rem;
  }

  .dashboard-top {
    margin: 0 2rem;
  }

  .transactions {
    margin: 2rem 2rem;
  }

  .dashboard-footer {
    margin: 0 2rem;
  }
}

/* @media (max-width: 1150px) {
  .transactions {
    grid-template-columns: 1fr;
  }
} */

@media (max-width: 770px) {
  .sidebar {
    position: fixed;
    background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
    top: 0;
    bottom: 0;
    z-index: 5;
    left: 0;
  }
}

@media (min-width: 450px) and (max-width: 920px) {
  .dashboard-top {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 449px) {
  .btn-user,
  .btn-toggle {
    font-size: 1.2rem !important;
  }

  .dashboard-top {
    grid-template-columns: repeat(1, 1fr);
    margin: 2rem 0.5rem;
  }

  .transactions {
    margin: 0rem 0.5rem;
  }

  .dashboard-footer {
    margin: 0rem 0.5rem;
  }
}

@media (max-width: 450px) {
  .search-area {
    text-align: left;
  }

  .top-nav {
    padding: 1rem 1.2rem 0.5rem 1.2rem;
  }

  .price-side {
    padding: 1.2rem 0.5rem;
  }
}

@media (max-width: 280px) {
  .search-field {
    width: 200px;
  }
}

@media (max-width: 300px) {
  .close-div {
    display: block !important;
    position: absolute;
    right: 35px;
    top: 5px;
  }
}

.cv-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

.cv-select-container {
  display: flex;
  text-align: center;
  justify-content: center;
  border: dotted;
  flex-direction: column;
  max-width: 120vh;
  height: 30vh;
  margin: auto;
  cursor: pointer;
  background-color: #f5f5f5;
}

.cv-select-container p {
  text-align: center;
  font-size: 16px;
}

.responsive-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .responsive-img {
    max-width: 50%;
  }
}

.cv-info-container {
  margin: 100px auto;
}

.cv-form-btn {
  float: right;
}

.form-fade-enter {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.form-fade-enter-active {
  opacity: 1;
}

.form-fade-exit {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.form-fade-exit-active {
  opacity: 0;
}

.cv-dropdown {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.dropdown-button-cv {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-menu-cv {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-item-cv {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  color: #333;
}

.dropdown-item-cv:hover {
  background-color: #f0f0f0;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #f9f9fa;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease-in-out;
  width: 250px;
}

.search-container.expanded {
  width: 300px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  background-color: #f9f9fa;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.search-button i {
  color: #333;
}

.PI-container {
  padding: 7% 10%;
}

.PI-Home-Input {
  flex: 0 0 calc(33.33% - 1rem);
  margin-bottom: 1rem;
}

.PI-checkbox {
  padding: 0 5% 0 5%;
}

.interview-home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.interview-home-card {
  width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 15px;
}

.interview-qs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh !important;
}

.interview-warmup-card {
  width: 100%;
  border-radius: 15px;
}

.interivew-screen-button {
  background-color: #5cb3cf;
  color: floralwhite;
}

.interview-qs-image {
  max-width: 40%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.interview-qs-audio-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.interview-qs-audio-buttons-small {
  margin-top: 10px;
  background-color: #5cb3cf;
  color: floralwhite;
}

.interview-qs-audio-buttons-large {
  margin-top: 12px;
  font-size: 14px;
  padding: 10px 10px;
  background-color: #5cb3cf;
  color: floralwhite;
}

.project-button:hover {
  background-color: #2bafdb;
  color: floralwhite;
}

.logout-btn {
  background-color: #5cb3cf;
  color: white;
  border-radius: 20px;
  width: 100px;
}

.verify-account-btn {
  background-color: #5cb3cf;
  color: white;
  border-radius: 20px;
}

.faq-header {
  background-color: lightgray;
}

.interview-home-fields {
  display: flex !important;
  flex-direction: row !important;
}

.second-text-field {
  margin-left: 20px !important;
}

@media (max-width: 768px) {
  .interview-home-fields {
    flex-direction: column !important;
  }

  .second-text-field {
    margin-left: 0px !important;
  }
}

.admin-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: url("./assets/adminbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bgWhite {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
  border-radius: 30px;
  width: 100% !important;
}

.verify-title {
  font-weight: 600;
  margin-top: 70px;
  font-size: 24px;
  text-align: center;
}

.verify_otp_btn {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.customBtn {
  border-radius: 25px;
  padding: 12px 20px !important;
}

.otp-timer {
  text-align: right;
  margin-right: 50px !important;
}

.verify-form {
  padding: 0 50px;
}

.verify-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  margin-left: 15px;
  border: 2px solid black !important;
}

.admin-reset-form {
  padding: 0 20%;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: #6e7881;
  border-radius: 20px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: none !important;
  border-radius: 20px !important;
  background: initial;
  background-color: #5cb3cf !important;
  color: #fff;
  font-size: 1em;
}

.analytic-chart {
  background-color: #ffffff !important;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
}

.css-1v4ccyo {
  border-radius: 20px;
}

.table-responsive {
  height: 160px;
  overflow-y: hidden;
  border: 1px solid lightgrey;
  border-radius: 7px;
}

.active-pie-gragh {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 10px;
}

.admin-overview {
  color: #171717;
  text-shadow: 0 0 #171717;
  column-gap: 1rem;
}

.admin-bullet {
  height: 12px;
  width: 12px;
  background: #0084ff;
  display: block;
  border-radius: 50%;
}

.organ-bullet {
  height: 12px;
  width: 12px;
  background: #1ab7ea;
  display: block;
  border-radius: 50%;
}

.over-credientials {
  column-gap: 2rem;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 15px !important;
}

.reset-password-top {
  padding: 20px;
}

.create-account {
  color: #000;
  font-size: 30px;
  font-style: normal;
  line-height: 183.2%;
}

.signup-p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.reset-btn {
  width: 40%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #5cb3cf;
}

.reset-text {
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cv-box-margin {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .reset-btn {
    width: 100%;
  }
}

.email-validation {
  margin-top: 10px;
  margin-bottom: -20px;
  color: #ea4335;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
  text-transform: capitalize;
}

.resend-email-top {
  margin: auto;
  justify-content: center;
  display: flex;
}

.resend-email-second {
  margin: 50px 0 0 0;
  text-align: center;
}

.resend-email-second .email-first {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.desc-email-grey {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.desc-email-sample {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}

.didnt-recieve-email {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 183.2%;
  margin-top: 50px;
}

.resend-email-btn {
  margin: auto;
  justify-content: center;
  display: flex;
  width: 60%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #66b855;
}

.cv-box {
  width: 390px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 1rem;
  height: 110.07px;
}

.analytical-overview-chart {
  background-color: #ffffff;
}

.analytics-space {
  column-gap: 2rem;
  display: flex;
}

.active-status {
  background: #50b482;
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.inactive-status {
  background-color: rgb(255, 193, 7);
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.analytics-graph {
  width: 80% !important;
  border-radius: 10px !important;
  height: 80%;
}

@media (max-width: 1900px) {
  .analytics-graph {
    width: 100% !important;
  }

  .active-admin-chart,
  .analytical-overview-chart {
    width: 100%;
  }

  .side-charts {
    flex-direction: column !important;
  }

  .analytical-overview-chart {
    margin-top: 10px;
  }

  /* .analytics-graph-width {
    width: 112%;
  }

  .cv-box {
    width: 270px;
    margin-left: 75px;
   
  } */
}

@media (max-width: 1604px) {
  .side-charts {
    flex-direction: row !important;
  }

  .active-admin-chart,
  .analytical-overview-chart {
    width: 50%;
  }

  .analytical-overview-chart {
    margin-top: 0px;
  }
}

@media (max-width: 670px) {
  .side-charts {
    flex-direction: column !important;
  }

  .active-admin-chart,
  .analytical-overview-chart,
  .navigation-card {
    width: 100%;
  }
}

.cv-icon {
  font-size: 3.2rem;
  color: rebeccapurple;
}

.calender-space {
  column-gap: 0.5rem;
}

.calender-icon {
  font-size: 1.5rem;
}

.cv-title {
  font-weight: 400;
  color: #101828;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  margin: 0;
}

.bg-organization {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  height: 300px;
}

.cv-number {
  font-weight: 500;
  color: #171717;
  font-size: 2rem;
}

.btn-analytics {
  background: #50b482 !important;
  color: white !important;
}

.navigation-name {
  color: #597c93;
  font-size: 1.2rem;
  font-weight: bold;
}

.css-1xsto0d {
  font-weight: 700;
}

.navigation-link-icon {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  background: #00a9ff;
  border-radius: 0 15px 15px 0;
}

.navigation-link {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  position: relative;
  top: -1px;
  font-weight: bold;
  text-shadow: 0 0 white;
}

@media (max-width: 768px) {
  .resend-email-btn,
  .error-btn {
    width: 100%;
  }
}

.resend-email-text {
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.password-match {
  color: #66b855;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
  text-transform: capitalize;
}

.footer-super-admin {
  background-color: transparent;
  padding: -3rem 1rem;
  border-radius: 10px;
  width: 100%;
  padding-right: 20px;
  position: relative;
  z-index: auto;
}

.admin-active-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.navigation-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 360px;
  height: auto;
  border-bottom: 4.53px solid #50b482;
  height: 120px;
}

.card-header {
  background: url("./assets/img/bg-ex.png") no-repeat;
  background-size: contain;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (min-width: 1600px) {
  .card-header {
    background-size: 100% auto; /* Adjust as needed for larger screens */
  }
}

.icon-text-gap {
  display: flex;
  align-items: center;
}

.icon-text-gap img {
  margin-right: 10px;
  /* Adjust the gap as needed */
}

.card-icon-background {
  background: #50b482;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.card-content {
  padding: 1rem;
  text-align: center;
  margin-top: -15px;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

@media (max-width: 450px) {
  .cv-box {
    width: 350px;
  }
  
}

/* 
@media (max-width: 1199px) {

  .d-flex {
    flex-wrap: wrap;
   
  }

  .footer-navigation {
    grid-template-columns: 1fr;
  }
} */

@media (min-width: 1401px) and (max-width: 1700px) {
  .cv-box {
    width: 330px;
    height: 143.1px;
  }

  .navigation-card {
    width: 310px;
  }
}

@media (min-width: 2200px) and (max-width: 3000px) {
  .cv-box {
    width: 450px;
  }

  .navigation-card {
    width: 310px;
  }
}

@media (min-width: 3100px) and (max-width: 4000px) {
  .cv-box {
    width: 650px;
  }

  .navigation-card {
    width: 650px;
  }
}

@media (min-width: 5100px) and (max-width: 6000px) {
  .cv-box {
    width: 1200px;
  }

  .navigation-card {
    width: 1000px;
  }
}

@media (min-width: 7100px) and (max-width: 8000px) {
  .cv-box {
    width: 1500px;
  }

  .navigation-card {
    width: 1450px;
  }
}

@media (max-width: 1605px) {
  .s-transactions {
    flex-direction: column !important;
  }

  .right-info {
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .analytics-space {
    flex-direction: row;
  }

  .mt-lg-0 {
    margin-top: -19px !important;
  }

  .cv-box {
    /* width: 100%; */
    margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .analytics-space {
    flex-direction: column;
  }
}

@media (max-width: 1250px) {
  .footer-super-admin {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 1400px) {
  .animation-loader-width {
    width: 50% !important;
  }

  .footer-navigation {
    grid-template-columns: repeat(1, 1fr);
  }

  .s-transactions {
    row-gap: 35rem;
  }
}

@media (max-width: 1100px) {
  .s-transactions {
    row-gap: 25rem;
  }

  .footer-super-admin {
    margin-top: 15px;
  }
}

@media (max-width: 770px) {
  .animation-loader-width {
    width: 65% !important;
  }
}

@media (max-width: 550px) {
  .s-transactions {
    row-gap: 15rem;
  }
}

@media (max-width: 620px) {
  .left-stats {
    grid-template-columns: 1fr 1fr;
  }

  .cv-box-margin:nth-child(3) {
    grid-column: 1 / span 2;
  }

  .s-dashboard-top {
    margin: 0rem 0.7rem;
  }

  .s-transactions {
    margin: 2rem 0.7rem;
  }

  .footer-super-admin {
    padding: 1rem 0rem;
  }
}

@media (max-width: 450px) {
  .s-dashboard-top,
  .left-stats,
  .navigation-card {
    grid-template-columns: 1fr;
  }

  .navigation-card {
    width: 340px;
  }

  .cv-box-margin:nth-child(3) {
    grid-column: 1 / span 1 !important;
  }

  .cv-box {
    margin-top: 40px;
  }
}

@media (max-width: 1024px) {
  .cv-box {
    margin-top: 40px;
  }
}

@media (max-width: 420px) {
  .cv-box {
    width: 330px;
  }

  .navigation-card {
    width: 320px;
  }
}

/* 
@media (max-width:390px) {
  .cv-box{
    width: 365px;
  }

  .navigation-card
  {
    width: 355px;
  }
} */

@media (min-width: 1606px) {
  .side-content {
    height: 92%;
  }
}

@media (min-width: 1400px) and (max-width: 1605px) {
  .side-content {
    height: 95%;
  }
}

@media (min-width: 771px) and (max-width: 1399px) {
  .side-content {
    height: 96%;
  }
}

@media (max-width: 770px) {
  .close-div {
    display: block !important;
  }
}

.reactour__popover {
  width: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 14px 0.5em 3em !important;
  border: 4px solid transparent;
  border-bottom-color: #56367f;
}

.reactour__dot {
  background-color: #50b482 !important;
  /* Change this to your desired color */
}

.reactour__dot--active {
  background-color: #50b482 !important;
  /* Change this to your desired active color */
}

.reactour__popover span {
  background-color: #50b482 !important;
}

.reactour__close-button {
  width: 12px !important;
  height: 12px !important;
  color: lightgray;
}

/* Badge styles */
.reactour__badge {
  background-color: #50b482 !important;
  color: white !important;
  border-radius: 50% !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
}

/* Content styles */
.reactour__content {
  padding: 20px !important;
  font-size: 16px !important;
}

/* Navigation styles */
.reactour__navigation {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 20px !important;
}

.reactour__navigation button {
  background-color: #50b482 !important;
  color: white !important;
  border: none !important;
  padding: 10px 15px !important;
  cursor: pointer !important;
  border-radius: 5px !important;
}

.reactour__navigation button:disabled {
  background-color: grey !important;
  cursor: not-allowed !important;
}

.reactour__navigation .reactour__dot {
  background-color: #50b482 !important;
}

.reactour__navigation .reactour__dot--active {
  background-color: #50b482 !important;
}

/* Arrow styles */
.reactour__arrow {
  border-color: #50b482 !important;
}

.reactour__arrow--inverted {
  border-color: white !important;
}

.upload-cv-container {
  padding: 40px;
  background-color: #eeeef4;
  height: 100% !important;
  overflow: auto;
}

.box {
  width: 49%;
  padding: 20px;
  background-color: #f0f0f0;
  text-align: center;
  border: 1px solid #ccc;
}

.cv-skim-card {
  border-radius: 8px;
  background: #ffff;
  border: 4px solid transparent;
  flex: 1;
  margin: 0 10px;
}

.cv-skim-inside {
  cursor: pointer;
  border: 2px solid lightgray;
  border-style: dashed;
  height: 100% !important;
}

.cv-skim-cloud-img {
  width: 120px;
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
}

.cv-small-text {
  color: #a0a0a0;
}

.org-btn {
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  width: 159px;
  height: 55px;
  top: 845.21px;
  left: 978px;
  gap: 0px;
  border-radius: 7px;
  color: white;
}

.org-btn:hover {
  color: #f1f1f1;
}

.org-btn-close {
  background-color: #808080;
  width: 159px;
  height: 55px;
  top: 845.21px;
  left: 978px;
  gap: 0px;
  border-radius: 7px;
  color: white;
}

.org-btn-close:hover {
  color: #f1f1f1;
  background-color: #808080;
}

.cv-skim-last {
  text-align: center;
}

.skim-fields {
  margin: 10px 0px;
  padding: 20px;
  display: block;
  border-radius: 16px;
  width: 100%;
  border: none;
}

.skim-labels {
  font-weight: 600;
  margin-top: 10px;
  font-size: 22px;
  margin-left: 10px;
}

.skim-btns {
  width: 120px;
  border-radius: 16px !important;
  padding: 8px 16px !important;
  text-transform: capitalize !important;
  font-size: 20px !important;
}

.cv-label2 {
  display: block;
}

.cv-label3 {
  display: none;
}

@media (max-width: 767px) {
  .cv-label2 {
    display: none;
  }

  .cv-label3 {
    display: block;
  }

  .cv-skim-container-row {
    flex-direction: column !important;
  }
}

.selected-cv-skim {
  text-align: center;
}

.remove-file {
  color: lightgray;
  margin-left: 22px;
  font-size: 16px;
}

.cv-skim-filters {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.toggle-buttons-cv {
  display: flex;
  margin-top: -30px;
}

.toggle-buttons-cv button {
  background-color: #08a0d1;
  color: white;
  border: none;
  margin-bottom: 10px;
  padding: 8px 20px;
}

.clear-btn {
  color: #a7a7a7 !important;
  background: transparent !important;
  box-shadow: none !important;
  border: 2px solid #a7a7a7 !important;
}

.submit-btn {
  margin-left: 20px !important;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  color: white !important;
}

.upload-files-btn {
  background: none;
  color: black;
  border: 2px solid #a7a7a7;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.logout-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.logout-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  text-align: center;
}

.logout-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.logout-modal-close:hover,
.logout-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.logout-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.logout-button,
.cancel-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.logout-button {
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  color: white;
}

.cancel-button {
  background-color: #808080;
  color: white;
}

@media (max-width: 480px) {
  .s-dashboard-top {
    margin-top: 30px;
  }
}

.login-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

/* new login page styling */
.login-page-container {
  min-height: 100vh;
}

.login-form-logo {
  width: 12rem;
  height: auto;
  object-fit: contain;
}

.login-form-container {
  background-color: white;
  border-radius: 30px;
  padding: 25px !important;
  font-family: "Onest", sans-serif;
}

.login-form-body h4 {
  font-size: 2rem;
  font-family: "Onest", sans-serif;
}

.login-form-input label {
  color: black;
  margin-left: 5px;
}

.login-form-input input,
.otp-form-container input {
  border-radius: 5px;
  padding: 15px 18px;
  background-color: #f0f3f6;
  border: none;
}

.login-form-input input:focus {
  outline: none;
}

.login-form-input input.password {
  border: none;
  background-color: #f0f3f6;
}

input.password:focus {
  box-shadow: none;
  border-right: none;
}

.input-group {
  border-radius: 5px;
  background-color: #f0f3f6;
}

.input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 14px;
}

/* Basic styling for the checkbox */
.custom-checkbox {
  appearance: none;
  /* Remove default styles */
  width: 15px;
  height: 15px;
  border: 2px solid #b3b7ee;
  /* Default border color */
  border-radius: 4px;
  /* Optional: round corners */
  position: relative;
  cursor: pointer;
  padding: 2px;
}

/* Styling for the checked state */
.custom-checkbox:checked {
  background-color: #5051fa;
  border-color: #5051fa;
  color: white;
  /* Change to your desired color */
}

/* Optional: Styling for a checkmark */
.custom-checkbox:checked::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-form-button {
  padding: 15px 25px;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  border-radius: 7px;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}

.login-form-button.back-btn-cv {
  background: gray !important;
  font-weight: 500;
}

.welcome-audio-buttons button {
  padding: 12px 20px;
  color: white;
  border: none;
  border-radius: 12px;
  background-color: #5c7ce5;
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}

.login-form-button.disabled {
  background: gray;
}

.login-page-right {
  position: relative;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  border-radius: 27px;
  overflow: hidden;
}

.login-search-img {
  width: 75%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.polygon-top-left {
  width: 50px;
  height: auto;
  position: absolute;
  top: -1%;
  left: -20px;
}

.cells-top-right {
  height: 100px;
  width: auto;
  position: absolute;
  top: 1%;
  right: 3%;
}

.polygon-bottom-left {
  height: 140px;
  width: auto;
  position: absolute;
  bottom: 0%;
  left: -30px;
}

.polygon-bottom-right {
  height: 180px;
  width: auto;
  position: absolute;
  bottom: 0%;
  right: -60px;
}

.login-stats {
  position: absolute;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1280px) {
  .login-stats {
    width: 100% !important;
  }

  .login-page-container {
    min-height: 90vh !important;
    max-height: 90vh !important;
    align-self: center;
  }

  .signup-right-girl {
    height: 250px !important;
    width: auto;
    transform: translateX(-20px) !important;
  }
}

.login-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.password-textfield {
  background-color: #f0f3f0;
  border-radius: 5px;
}

.table-scroll {
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
}

.reactour__popover button[aria-label^="Go to step"] {
  background: #50b482 !important;
  /* border: 1px solid red !important; */
  /* color: yellow !important; */
}

/* .reactour__popover button[aria-label^="Go to step"][style*="background: var(--reactour-accent, #007AFF);"] {
  background: rgb(26, 181, 195) !important;
  border: 1px solid rgb(90, 203, 34) !important;
  color: rgb(145, 22, 73) !important;
} */

.profile-image {
  height: 35px;
  padding: 6px;
  background-color: rgb(86, 54, 127);
  border-radius: 50%;
}

.table-scroll tbody tr:nth-child(odd) {
  background-color: #e4e7ec;
  /* Grey */
}

.table-scroll tbody tr:nth-child(even) {
  background-color: #ffffff;
  /* White */
}

.btn-close-otp {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0px !important;
  margin-top: 2px !important;
  justify-content: center;
  background-color: #56367f;
  border-radius: 50%;
  color: white;
  font-weight: 500 !important;
  padding: 2px !important;
}
.verify-otp-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 14px;
  color: #953ec7;
  font-weight: 800;
  border: 2px solid #953ec7;
}

.verify-otp-form input:focus {
  color: #953ec7 !important;
}

.reactour__close-button{
  color: #50B482; /* Change the close button icon color */
  border-radius: 50%;
  border: 2px solid black;
}



@media (max-width: 767px) {
  .item-icon {
    width: 90px;
    height: 90px;
    opacity: 1.5;
  }
}



/* Target the entire icon container */
.custom-swal-popup .swal2-icon.swal2-info {
  border-color: #50b482 !important; /* Change the circle border color */
}

/* Target the icon's exclamation mark color */
.custom-swal-popup .swal2-icon.swal2-info::after {
  background-color: #50b482 !important; /* Change the exclamation mark background color */
  color: #fff !important; /* If needed, change the exclamation mark color itself */
}

/* Optional: Ensure any inner border or shadow uses the custom color */
.custom-swal-popup .swal2-icon.swal2-info::before {
  background-color: #50b482 !important; /* Change background of the icon */
}
.custom-swal-icon .swal2-icon.swal2-info {
  border-color: #50b482 !important; /* Change the circle's border color */
}

.custom-swal-icon .swal2-icon.swal2-info::after {
  background-color: #50b482 !important; /* Change the color of the "i" icon's background */
  color: #50b482 !important; /* Ensure the "i" icon itself is colored */
}