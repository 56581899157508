.assessment-table {
        height: 100dvh;
        margin: 2rem 1rem;
}

.bg-test-div {
        background: #F5F5F5;
        border-radius: 10px;
        border-right: 5px solid white;
        padding: 1rem;
        overflow: hidden;
        height: 90px;
        transition: height .3s linear;
        box-sizing: 0px 0px 0px 0px #08a0d1;
        box-shadow: 1px 2px 14px 0px lightgray;
}

.test-div {
        width: 500px;
}

.index-div {
        width: 100px;
}

.bg-test {
        display: flex;
        flex: 1;
        align-items: center;
        column-gap: 25rem;
}

.test-flex {
        display: flex;
        flex: 1;
        align-items: flex-start;
        /* column-gap: 20rem; */
}

.test-caption {
        font-size: 1.2rem;
        text-shadow: 0 0 #36454F;
        color: #36454F;
}

.btn-del-assess {
        color: red;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.02rem;
        transition: all .2s linear;
        cursor: pointer;
}

.btn-del-assess:hover {
        color: rgb(253, 92, 92);
}

.btn-edit-assess {
        color: #36454F;
        font-size: 1.3rem;
        cursor: pointer;
        transition: all .2s linear;
        background-color: transparent;
        border: none;
}

.btn-edit-assess:hover {
        color: #4c5f6b;
}

.action-question {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 5px;
}

.btn-action-space {
        position: relative;
        top: -6px;
}

.test-btn-toggle {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
}

.test-description {
        margin-top: 1.2rem;
}

.test-content {
        width: 40%;
        text-align: justify;
        height: 80px;
        overflow-y: auto;
}

.divider {
        height: 1px;
        background: lightgray;
}

.question-add-btn {
        display: flex;
        column-gap: 1rem;
}

.test-height-toggle {
        height: 280px;
}

/* .test-name-width {
        width: 200px;
} */

.text-area-test {
        resize: none;
}

.action-space {
        display: flex;
        align-items: center;
        column-gap: 20rem;
}

.img-faeye-icon {
        border: none;
        outline: none;
}

@media (max-width: 390px) {
        
}
@media (min-width:1774px) and (max-width: 1880px) {
        .bg-test {
                column-gap: 20rem;
        }

        /* .test-flex {
                column-gap: 15rem;
        } */
}

@media (min-width:1200px) and (max-width: 1773px) {
        .bg-test {
                column-gap: 10rem;
        }


}

@media (min-width: 1393px) and (max-width: 1680px) {
        .bg-test {
                column-gap: 5rem;
        }

        .test-div {
                width: 350px;
        }

        /* .test-flex {
                column-gap: 10rem;
        } */

        .action-space {
                column-gap: 20rem;
        }
}

@media (min-width: 1208px) and (max-width: 1392px) {
        .bg-test {
                column-gap: 0rem;
        }

        .test-div {
                width: 300px;
        }

        .action-space {
                column-gap: 10rem;
        }
}

@media (min-width: 1100px) and (max-width: 1207px) {
        .bg-test {
                column-gap: 0rem;
        }

        .test-div {
                width: 300px;
        }

        .action-space {
                column-gap: 7rem;
        }


}

@media (min-width: 572px) and (max-width: 1099px) {
        /* .bg-test-div {
                height: 145px;
        } */
/* 
        .test-height-toggle {
                height: 360px !important;
        } */

        .bg-test {
                column-gap: 0rem;
                align-items: start;
        }

        .test-flex {
                column-gap: 0rem;
                flex-direction: column;
                row-gap: 1rem;
        }

        .test-div {
                width: auto;
        }

        .action-space {
                column-gap: 3rem;
        }

        .action-question {
                top: 0px !important;
        }

        .test-content {
                width: auto;
        }
}

@media (min-width: 445px) and (max-width: 571px) {
        .test-height-toggle {
                height: 360px;
        }

        .bg-test {
                column-gap: 0rem;
                align-items: start;
        }

        .action-question {
                top: 0px !important;
        }

        .test-flex {
                column-gap: 0rem;
                flex-direction: column;
                row-gap: 1rem;
        }

        .test-div {
                width: auto;
        }

        .action-space {
                column-gap: 2rem;
        }

        .test-content {
                width: auto;
        }
}

@media (max-width: 444px) {
        .assessment-table {
                margin: 1rem;
        }

        .test-height-toggle {
                height: 360px !important;
        }

        .bg-test {
                column-gap: 0rem;
                align-items: start;
        }

        .action-question {
                top: 0px !important;
        }

        .test-flex {
                column-gap: 0rem;
                flex-direction: column;
                row-gap: 1rem;
        }

        .action-space {
                column-gap: 2rem;
                flex-direction: column;
                margin-left: -120px;
        }

        .btn-action-space {
                position: relative;
                top: -8px;
        }

        .test-content {
                width: auto;
        }

        .bg-test-div {
                /* height: 145px; */
                padding: .5rem;
        }

        .test-div {
                width: 250px;
        }
}

@media (max-width: 630px) {
        .modal-btn-font {
                font-size: 13px;
        }
}

.modal.show .modal-dialog {
        max-width: 700px !important;
}

.btn-close {
        color: white !important;
}


.download-icon-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
      
      .back-arrow-icon {
        margin-left: 20px;
        cursor: pointer;
      }
      
      .download-icon {
        cursor: pointer;
        width: 110px;
      }
      